var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"by94jJM7WaKSI20HWYOSS"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs'
import { Replay } from '@sentry/react'
import posthog from 'posthog-js'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

init({
  dsn:
    SENTRY_DSN ||
    'https://1524385d9c9b437095e5165e30bc57e5@o443412.ingest.sentry.io/4504960793116672',
  tracesSampleRate: 0.5,
  environment: process.env.APP_ENV,
  autoSessionTracking: true,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  integrations: [
    new Replay({
      blockAllMedia: true
    }),
    new posthog.SentryIntegration(posthog, 'bookee-apps', 29252)
  ]
})
